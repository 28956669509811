import { AccordionDetails, AccordionSummary, Button, Box, Typography, styled, TextField } from "@mui/material"
import { DropdownNestedMenuItem } from "../../components/NastedDropdown/Dropdown"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const DropdownNestedMenuItems = styled(DropdownNestedMenuItem)(() => ({
    padding: "0px 5px 0px 5px !important",
    ".MuiList-root-MuiMenu-list": {
        paddingTop: "4px !important",
        paddingBottom: "4px !important",
    },
}))

export const LoadingBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "52vh",
}))

export const InputTextField = styled(TextField)(() => ({
    width: "20%",
    marginLeft: 1,
    padding: "0px",
    "& .MuiInputBase-root": {
        height: "40px",
    },
    "& .MuiInputLabel-root": {
        height: "auto",
        lineHeight: "40px",
        textAlign: "center",
    },
}))

export const InnerBoxOne = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "3%",
    marginTop: "3%",
}))

export const ModalMainBox = styled(Box)(({ isdarktheme }) => ({
    width: 400,
    maxHeight: "80vh",
    padding: "25px",
    backgroundColor: isdarktheme === "true" ? "#0b1727 !important" : "white",
    margin: "auto",
    marginTop: "2%",
    overflowY: "auto",
    position: "relative",
    '@media (max-width: 600px)': {
        width: 250,
    }
}))
export const RoundOffButton = styled(Button)(() => ({
    marginLeft: 1,
    '@media (max-width: 600px)': {
        fontSize: "14px",
    }
}))

export const ModelTitleTypography = styled(Typography)(({ isdarktheme }) => ({
    fontSize: "21px",
    // color: "var(--primary-text-color)",
    color: isdarktheme === "true" ? "#e0e0e0e3" : "#544f5a",

}))
export const LeaveTextTypography = styled(Typography)(({ isdarktheme }) => ({
    // color: "var(--primary-text-color)",
    color: isdarktheme === "true" ? "#e0e0e0e3" : "#544f5a",
    '@media (max-width: 600px)': {
        fontSize: "14px",
    }
}))
export const LeaveMessageTypography = styled(Typography)(() => ({
    '@media (max-width: 600px)': {
        fontSize: "14px",
    }
}))

export const EmployeesAccordionSummary = styled(AccordionSummary)(() => ({
    cursor: "default !important",
    textTransform: "capitalize",
    backgroundColor:
        "var(--accordian-background-color)",
    color: "var(--primary-color) !important",
    fontWeight: "bold",
    fontSize: "130%",
    boxShadow:
        "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
    ".MuiSvgIcon-fontSizeMedium": {
        fill: "var(--primary-text-color) !important",
    },
}))

export const EmployeesAccordionDetails = styled(AccordionDetails)(() => ({
    textTransform: "capitalize",
    backgroundColor:
        "var(--accordian-background-color)",
    color: "var(--primary-text-color) !important",
    boxShadow:
        "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
}))

export const EmpNameAccordionSummary = styled(AccordionSummary)(() => ({
    background:
        "var(--subTaskdataGrid-background-color)",
    color: "var(--primary-text-color) !important",
    boxShadow:
        "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
    ".MuiSvgIcon-fontSizeMedium": {
        fill: "var(--primary-text-color) !important",
    },
}))

export const EmpNameAccordionDetails = styled(AccordionDetails)(() => ({
    backgroundColor:
        "var(--subTaskdataGrid-background-color)",
    color: "var(--primary-text-color) !important",
    boxShadow:
        "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
}))

export const NoRecordFound = styled(Typography)(() => ({
    color: "var(--secondary-text-color)",
    display: "flex",
    justifyContent: "center"
}))

export const EmployeeExpandMoreIcon = styled(ExpandMoreIcon)(() => ({
    display: "none",
    fill: "var(--primary-text-color) !important",
}))

export const EmpNameExpandMoreIcon = styled(ExpandMoreIcon)(() => ({
    color: "var(--primary-text-color) !important",
}))

export const CompanyPolicyBox = styled(Box)(() => ({
    borderRadius: "50px",
    marginBottom: "10px"
}))

export const MainBox = styled(Box)(() => ({
    color: "var(--primary-color)",
    textAlign: "center",
    marginBottom: "20px",
    userSelect: "none",
    borderBottom: "1px solid var(--table-border-color)",
}))

export const TitleTypography = styled(Typography)(() => ({
    fontWeight: "bold",
    fontSize: "23px"
}))
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CardContent, Tooltip, Typography } from "@mui/material";
import { Assignment as AssignmentIcon } from "@mui/icons-material"; // Import necessary icons
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import ListAltIcon from "@mui/icons-material/ListAlt";

import { setToast, setToastMsg, setToastType } from "../../Slices/toastSlice";
import { setGetNotificationCount } from "../../Slices/selectedActive";
import {
  getNotificationByEmpId,
  upsertNotificationsMarkAsRead,
} from "../../Services/DashboardServices";
import {
  CommanBox,
  InnerCardOne,
  ShowAllButton,
} from "../../Styles/Notification/NotificationStyle";
import "./Notification.css";

function Notification(props) {
  const { isOpen, isMobile, toolTipStyle, onClose } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const user = useSelector((state) => state.selectedActiveView.role);
  const currentEmployeeId = useSelector(
    (state) => state.addEmployee.current?.empId
  );
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const currentAdminId = useSelector(
    (state) => state.adminData.adminData?.empId
  );
  const employeeName = useSelector(
    (state) => state.selectedActiveView.employeeName
  );
  const employeeDisplay = user === "employee";
  const adminLogin = user === "admin";
  const empId = employeeDisplay ? currentEmployeeId : currentAdminId;

  const [notifications, setNotifications] = useState([]);
  // const [pageSize, setPageSize] = useState(5);
  // const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleReadMore = () => {
    setShowAll(!showAll);
    // setPageNumber((prevPageNumber) => prevPageNumber + 1);
    // setPageSize(-1);
    navigate("/notificationDetails");
    onClose();
  };

  const fetchNotification = async () => {
    const user = employeeDisplay ? "user" : adminLogin ? "admin" : "";
    const empId = employeeDisplay ? currentEmployeeId : currentAdminId;
    setIsLoading(true);
    if (user && empId) {
      try {
        const response = await getNotificationByEmpId(user, empId);
        if (response.status === 200 || response.status === 201) {
          setIsLoading(false);
          setNotifications((prevNotifications) => [
            ...response.data.notifications,
          ]);
          setTotalCount(response.data.totalCount);
        } else {
          setNotifications([]);
          setTotalCount(0);
        }
      } catch (error) {
        setIsLoading(false);
        console.log("error:", error);
      }
    }
  };

  const markAsRead = async (id, IsRead) => {
    const user = employeeDisplay ? "user" : adminLogin ? "admin" : "";
    const empId = employeeDisplay ? currentEmployeeId : currentAdminId;
    if (user && id) {
      try {
        const notifications = [{ id, empId, isRead: IsRead }];
        const postData = JSON.stringify({ notifications });
        const response = await upsertNotificationsMarkAsRead(user, postData);
        if (response.status === 200 || response.status === 201) {
          dispatch(setToast(true));
          dispatch(setToastMsg("Notification marked as read."));
          dispatch(setToastType("success"));
          dispatch(setGetNotificationCount(response?.data?.notificationCount));
          setNotifications((prevNotifications) =>
            prevNotifications?.map((notification) => {
              if (notification?._id === id) {
                const updatedUser = notification?.user?.map((user) =>
                  user?.empId === empId
                    ? { ...user, isRead: IsRead ? false : true }
                    : user
                );
                return { ...notification, user: updatedUser };
              }
              return notification;
            })
          );
        }
      } catch (error) {
        //   setDisplayMessageError(errorMessage ?? "")
      }
    }
  };

  const extractNotificationDetails = (message, type, user, assignBy) => {
    if (type === "Leave") {
      const leaveMessage = message ?? "";
      return { employee: "", leaveMessage, ticketNo: "", task: "" };
    } else {
      const parts = message.split(",");
      if (parts?.length === 3) {
        const ticketNo = parts[0].replace("Ticket No:", "").trim();
        const task = parts[1].replace("Task:", "").trim();
        const assignTo = parts[2].replace("Assigned to:", "").trim();
        const employee = employeeName === assignTo;
        const assignby = employee ? assignBy : "";
        return {
          ticketNo,
          task,
          assignTo,
          assignBy: assignby,
          employee: "",
          leaveMessage: "",
        };
      }
    }
    return {
      ticketNo: "",
      task: "",
      assignTo: "",
      assignBy: "",
      employee: "",
      leaveMessage: "",
    };
  };

  const renderIcon = (type) => {
    switch (type) {
      case "Leave":
        return (
          <WorkHistoryIcon
            sx={{
              color: isDarkTheme
                ? "var(--secondary-text-color)"
                : "text.secondary",
              marginTop: "2px",
            }}
          />
        );
      case "Task":
        return (
          <ListAltIcon
            sx={{
              color: isDarkTheme
                ? "var(--secondary-text-color)"
                : "text.secondary",
              marginTop: "5px",
            }}
          />
        );
      case "assignment":
        return (
          <AssignmentIcon
            sx={{
              color: isDarkTheme
                ? "var(--secondary-text-color)"
                : "text.secondary",
            }}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchNotification();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <>
      <div
        ref={containerRef}
        style={{
          backgroundColor: isDarkTheme ? "#0B1727" : "#FFFFFF",
          overflowY: showAll ? "auto" : "",
          maxHeight: showAll ? "450px" : "480px",
        }}
        className={`${isMobile ? "notification-dialog-mobile" : "notification-dialog"
          }`}
      >
        {isLoading ? <CommanBox>
          {"Loading..."}
        </CommanBox> : <div className="notifications">
          {notifications?.length > 0 ? (
            notifications?.map((notification) => {
              const { ticketNo, task, assignTo, assignBy, leaveMessage } =
                extractNotificationDetails(
                  notification?.message,
                  notification?.type ?? "",
                  notification?.user ?? "",
                  notification?.assignBy ?? ""
                );
              const truncatedTask =
                task?.length > 51 ? task.substring(0, 51) + "..." : task;
              const isRead = notification?.user?.find(
                (item) => item?.empId === empId
              )?.isRead;
              return (
                <InnerCardOne
                  style={{ cursor: "pointer" }}
                  onClick={() => markAsRead(notification?._id, isRead)}
                  key={notification._id}
                >
                  <CardContent
                    sx={{
                      padding: "2%",
                      "&:last-child": { paddingBottom: "2%" },
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "flex-start" }}>
                      {renderIcon(notification?.type ?? "")}
                      <div style={{ marginLeft: "8px" }}>
                        {leaveMessage ? (
                          <>
                            {/* <Typography variant="subtitle1" sx={{ fontWeight: isRead ? "normal" : "bold" }} color={isDarkTheme ? "var(--secondary-text-color)" : "text.secondary"} component="div">
                                            Employee: {employee}
                                        </Typography> */}
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: isRead ? "normal" : "bold" }}
                              color={
                                isDarkTheme
                                  ? "var(--secondary-text-color)"
                                  : "text.secondary"
                              }
                              component="div"
                            >
                              {leaveMessage}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: isRead ? "normal" : "bold" }}
                              color={
                                isDarkTheme
                                  ? "var(--secondary-text-color)"
                                  : "text.secondary"
                              }
                              component="div"
                            >
                              {assignBy
                                ? `Assigned By: ${assignBy}`
                                : `Assigned To: ${assignTo}`}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: isRead ? "normal" : "bold" }}
                              color={
                                isDarkTheme
                                  ? "var(--secondary-text-color)"
                                  : "text.secondary"
                              }
                              component="div"
                            >
                              Ticket No: {ticketNo}
                            </Typography>
                            <Tooltip
                              slotProps={{
                                popper: {
                                  sx: toolTipStyle,
                                },
                              }}
                              title={task}
                              arrow
                            >
                              <Typography
                                sx={{
                                  cursor: "pointer",
                                  fontWeight: isRead ? "normal" : "bold",
                                }}
                                variant="body1"
                                color={
                                  isDarkTheme
                                    ? "var(--secondary-text-color)"
                                    : "text.secondary"
                                }
                              >
                                Task: {truncatedTask}
                              </Typography>
                            </Tooltip>
                          </>
                        )}
                      </div>
                    </div>
                  </CardContent>
                </InnerCardOne>
              );
            })
          ) : (
            <CommanBox>
              {"No notifications"}
            </CommanBox>
          )}
          {totalCount > 5 && notifications?.length !== totalCount && (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                padding: "10px",
              }}
            >
              <ShowAllButton onClick={handleReadMore}>Show All</ShowAllButton>
            </div>
          )}
        </div>}
      </div >
    </>
  );
}

export default Notification;

import React from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";

import {
	empTaskTableBodyCell,
	empTaskTableHeadCell,
} from "./CustomDesignMUI/CustomMUI";

function TableComponent(props) {
	const { array } = props;
	const leaveTypes = array?.map((item) => item?.leaveType);
	const allotteds = array?.map((item) => item?.allotted);
	const balances = array?.map((item) => item?.balance);

	return (
		<TableContainer
			sx={{
				backgroundColor: "var(--event-calendar-color)",
			}}
		>
			<Table sx={{
				'@media (max-width: 600px)': {
					width: '360%',
				},
			}}>
				<TableHead>
					<TableRow sx={{ background: "var(--background-table-borderHeader-color)" }}>
						{leaveTypes?.map((item, index) => (
							<TableCell key={index} sx={{ ...empTaskTableHeadCell }}>
								{item}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						{allotteds?.map((allotted, index) => (
							<TableCell sx={{ ...empTaskTableBodyCell }} key={index}>{allotted}</TableCell>
						))}
					</TableRow>
					<TableRow>
						{balances?.map((balance, index) => (
							<TableCell sx={{ ...empTaskTableBodyCell }} key={index}>{balance}</TableCell>
						))}
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer >
	);
}
export default TableComponent;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	Accordion,
	Box,
	Button,
	Typography,
} from "@mui/material";
import ArrowRight from "@mui/icons-material/ArrowRight";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Loader from "../Loader/Loader";
import {
	Dropdown,
	DropdownMenuItem,
} from "../NastedDropdown/Dropdown";
import TableComponent from "../ReusableComponents/TableComponent";
import { formatReverseDate } from "../ReusableComponents/UtilityFunctions";
import DialogComponent from "../ReusableComponents/DialogBoxComponents/DialogComponent";
import { setToast, setToastMsg, setToastType } from "../../Slices/toastSlice";
import { getLeavesDropdown, getleaveBalanacesData, upsertLeaveBalance } from "../../Services/LeaveManageServices";
import {
	DropdownNestedMenuItems,
	EmpNameAccordionDetails,
	EmpNameAccordionSummary,
	EmpNameExpandMoreIcon,
	EmployeeExpandMoreIcon,
	EmployeesAccordionDetails,
	EmployeesAccordionSummary,
	LoadingBox,
	NoRecordFound,
	ModelTitleTypography,
	LeaveTextTypography,
	LeaveMessageTypography,
	ModalMainBox,
	InnerBoxOne,
	InputTextField
} from "../../Styles/CompanyAndLeavePolicy/ViewAssignLeaveBalanceStyle";
import { CustomTextField } from "../../Styles/LeaveManagement/AddLeaveFormStyle";

function ViewAssignLeaveBalance() {
	const dispatch = useDispatch();
	const currentAdminId = useSelector((state) => state.adminData.adminData?.empId);
	const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);

	const [isLoading, setIsLoading] = useState(false);
	const [employeeLeaveBalance, setEmployeeLeaveBalance] = useState([]);
	const [leavesDropdownArray, setLeavesDropdownArray] = useState([]);
	const [selectedData, setSelectedData] = useState("");
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedEmployee, setSelectedEmployee] = useState(null);
	const [selectedLeaveData, setSelectedLeaveData] = useState(null);
	const [showLeaveBalance, setShowLeaveBalance] = useState(null);
	const [empId, setEmpId] = useState(null);
	const [isValid, setIsValid] = useState(false);
	const [adjustmentValues, setAdjustmentValues] = useState({});
	const [adjustmentMessages, setAdjustmentMessages] = useState({});
	const [error, setError] = useState({});
	const [reason, setReason] = useState("");

	const isAdjustmentValuesEmpty = () => {
		return Object.keys(adjustmentValues).length === 0 ||
			Object.values(adjustmentValues).every(value => value === "");
	};

	const handleChange2 = (leaveType, value) => {
		setAdjustmentValues((prevValues) => ({
			...prevValues,
			[leaveType]: value,
		}));
		handleAdjustmentChange(leaveType, value);
	};

	const handleChange = (data, month) => {
		if (selectedData !== month) {
			setShowLeaveBalance(null);
			setEmpId(null);
			setSelectedData(month);
			getleaveBalanaceData(data);
		}
	};

	const openModal = (employee) => {
		setSelectedEmployee(employee);
		setSelectedLeaveData(employee.leaveDetails);
		const initialLeaveDetails = employee?.empId === empId ? JSON.parse(JSON.stringify(showLeaveBalance ?? employee.leaveDetails)) : JSON.parse(JSON.stringify(employee.leaveDetails));
		for (const key in initialLeaveDetails) {
			if (initialLeaveDetails.hasOwnProperty(key)) {
				if (initialLeaveDetails[key]) {
					if (!initialLeaveDetails[key].assigned) {
						initialLeaveDetails[key].assigned = initialLeaveDetails[key].balance;
					}
				}
			}
		}
		setEmpId(employee?.empId)
		setShowLeaveBalance(initialLeaveDetails);
		setAdjustmentValues({});
		setModalOpen(true);
	};

	const onClose = () => {
		setSelectedLeaveData(null);
		setShowLeaveBalance(showLeaveBalance);
		setEmpId(empId);
		setAdjustmentValues({});
		setAdjustmentMessages({});
		setError({});
		setReason("");
		setModalOpen(false);
	};

	const onClose2 = () => {
		setSelectedLeaveData(null);
		setShowLeaveBalance(null);
		setEmpId(null);
		setAdjustmentValues({});
		setAdjustmentMessages({});
		setError({});
		setReason("");
		setModalOpen(false);
	};

	const getleaveBalanaceData = async (data) => {
		if (data) {
			try {
				setIsLoading(true);
				const response = await getleaveBalanacesData(data);
				if (response.status === 200) {
					setIsLoading(false);
					const formattedLeaveData = response?.data?.viewLeavesArray?.map(employee => {
						const leaveTypes = employee?.data?.map(leave => leave?.leaveType);
						const balances = employee?.data?.map(leave => leave?.balance);
						const leaveDetails = leaveTypes?.reduce((acc, leaveType, index) => {
							acc[leaveType] = { balance: balances[index] };
							return acc;
						}, {});
						return { ...employee, leaveDetails };
					});
					setEmployeeLeaveBalance(formattedLeaveData);
				} else {
					setIsLoading(false);
					setEmployeeLeaveBalance([]);
				}
			} catch (error) {
				setIsLoading(false);
			}
		}
	};

	const fetchLeavesDropdown = async () => {
		try {
			const response = await getLeavesDropdown();
			if (response?.status === 200) {
				setLeavesDropdownArray(response?.data?.leavesDropdownArray);
			} else {
				setLeavesDropdownArray([]);
			}
		} catch (error) { }
	};

	const handleSave = async () => {
		setError({});

		if (!reason) {
			setError({ reason: "This field is required." });
			return;
		}

		if (selectedEmployee && selectedLeaveData) {
			try {
				const leaveKeys = Object.keys(selectedLeaveData);
				const leaveArray = leaveKeys
					.filter((key, index) => index !== 0)
					.map(key => {
						return {
							leaveType: key,
							balance: Math.round(selectedLeaveData[key].balance * 100) / 100,
							assigned: selectedLeaveData[key].assigned,
							months: leaveKeys[0]
						};
					});
				const postData = JSON.stringify({
					empId: selectedEmployee?.empId,
					leaveBalance: leaveArray,
					updateReason: reason,
					adminId: currentAdminId,
				})

				const response = await upsertLeaveBalance(postData);
				if (response.status === 200) {
					onClose2();
					dispatch(setToast(true));
					dispatch(setToastMsg(response.data.message ?? "Leave balance updated successfully."));
					dispatch(setToastType("success"));
					setEmployeeLeaveBalance((prevData) => {
						return prevData?.map((item) => {
							if (item?.empId === selectedEmployee?.empId) {
								const newData = Object.keys(selectedLeaveData)?.map((key) => {
									const balance = selectedLeaveData[key]?.balance;
									return {
										leaveType: key,
										balance: typeof balance === 'string' ? balance : Math.round(balance * 100) / 100,
										allotted: typeof balance === 'string' ? "alloted" : Math.round(balance * 100) / 100,
									};
								});
								const updatedLeaveDetails = Object.keys(selectedLeaveData).reduce((acc, key) => {
									const leaveDetail = selectedLeaveData[key];
									if (leaveDetail) {
										acc[key] = {
											balance: typeof leaveDetail?.balance === 'string' ? leaveDetail?.balance : Math.round(leaveDetail?.balance * 100) / 100,
										};
									} else {
										acc[key] = {
											balance: 'Balance',
										};
									}

									return acc;
								}, {});
								return {
									...item,
									data: newData,
									leaveDetails: updatedLeaveDetails,
								};
							}
							return item;
						});
					});
				}
				else if (response.status === 201) {
					dispatch(setToast(true));
					dispatch(setToastMsg(response.data.message ?? "Adjustment exceeds available balance."));
					dispatch(setToastType("warning"));
				}
			} catch (error) {
				console.error("Error updating leave balance", error);
			}
		}
	};

	const handleAdjustmentChange = (leaveType, value) => {
		const numericValue = parseFloat(value);
		const currentBalance = showLeaveBalance[leaveType].balance;

		if (isNaN(numericValue)) {
			setAdjustmentValues((prevValues) => ({
				...prevValues,
				[leaveType]: ""
			}));
			setAdjustmentMessages((prevMessages) => ({
				...prevMessages,
				[leaveType]: {
					text: "",
					color: "green"
				}
			}));
			setIsValid(false);
			return;
		}

		const newBalance = currentBalance + numericValue;
		const newLeaveData = { ...selectedLeaveData };
		const oldBalance = { ...showLeaveBalance };
		for (const key in oldBalance) {
			if (oldBalance.hasOwnProperty(key)) {
				if (key) {
					newLeaveData[key].assigned = oldBalance[key].assigned;
				}
			}
		}
		newLeaveData[leaveType].balance = newBalance;

		if (numericValue < 0 && Math.abs(numericValue) > currentBalance) {
			setIsValid(true);
			setAdjustmentMessages((prevMessages) => ({
				...prevMessages,
				[leaveType]: {
					text: "Adjustment exceeds available balance.",
					color: "red"
				}
			}));
		} else {
			setIsValid(false);
			setAdjustmentMessages((prevMessages) => ({
				...prevMessages,
				[leaveType]: {
					text: numericValue >= 0
						? `Leave Balance added. Updated balance: ${newBalance.toFixed(1)}`
						: `Leave Balance deducted. Updated balance: ${newBalance.toFixed(1)}`,
					color: "green"
				}
			}));
		}
		setSelectedLeaveData(newLeaveData);
		setAdjustmentValues((prevValues) => ({
			...prevValues,
			[leaveType]: value
		}));
	};

	const renderForm = () => {
		return (<ModalMainBox isdarktheme={isDarkTheme ? "true" : "false"}>
			<IconButton
				onClick={onClose}
				sx={{ position: "absolute", top: 20, right: 10, color: isDarkTheme ? "#e0e0e0e3" : "#544f5a", }}
			>
				<CloseIcon />
			</IconButton>
			<ModelTitleTypography isdarktheme={isDarkTheme ? "true" : "false"}>
				Adjust Leave Balance for {selectedEmployee?.name}
			</ModelTitleTypography>
			{selectedLeaveData ? (
				Object.keys(selectedLeaveData)
					?.slice(1)
					?.map((leaveType) => {
						const leave = showLeaveBalance?.[leaveType];
						if (!leave) {
							return null;
						}

						return (
							<InnerBoxOne
								key={leaveType}
							>
								<Box sx={{ display: "flex", flexDirection: "column" }}>
									<LeaveTextTypography isdarktheme={isDarkTheme ? "true" : "false"} variant="body1">
										{leaveType}: {leave?.assigned?.toFixed(1)}
									</LeaveTextTypography>
									{adjustmentMessages[leaveType] && (
										<LeaveMessageTypography
											sx={{
												color: adjustmentMessages[leaveType].color,
												marginTop: 1,
												width: "100%",
											}}
										>
											{adjustmentMessages[leaveType].text}
										</LeaveMessageTypography>
									)}
								</Box>
								<InputTextField
									// label="Adjustment"
									value={adjustmentValues[leaveType] || ""}
									onChange={(e) => handleChange2(leaveType, e.target.value)}
									onKeyDown={(e) => {
										if (e.key === "Enter") {
											setAdjustmentValues((prevValues) => ({
												...prevValues,
												[leaveType]: "",
											}));
										}
									}}
									// onChange={(e) => {
									// 	setAdjustmentValues((prevValues) => ({
									// 		...prevValues,
									// 		[leaveType]: e.target.value,
									// 	}))
									// }
									// }
									// onKeyDown={(e) => {
									// 	if (e.key === "Enter") {
									// 		handleAdjustmentChange(
									// 			leaveType,
									// 			adjustmentValues[leaveType]
									// 		);
									// 		setAdjustmentValues((prevValues) => ({
									// 			...prevValues,
									// 			[leaveType]: "",
									// 		}));
									// 	}
									// }}
									type="number"
									InputProps={{
										inputProps: {
											step: "0.1",
											style: {
												height: "40px",
												padding: "10px",
												textAlign: "left",
											},
										},
									}}

								/>
							</InnerBoxOne>
						);
					})
			) : (
				<Typography>No leave data available.</Typography>
			)}
			<CustomTextField
				name="reason"
				sx={{ width: "100%", }}
				label="Title"
				value={reason}
				onChange={(event) => {
					setReason(event.target.value);
					if (error.reason) {
						setError({});
					}
				}}
				multiline
				rows={2}
			/>
			{error.reason && (
				<Typography marginBottom={1} color="error">{error.reason}</Typography>
			)}
			<Button variant="contained" onClick={handleSave} disabled={isValid || isAdjustmentValuesEmpty()}>
				Save
			</Button>
			<Button
				variant="outlined"
				onClick={onClose}
				sx={{ marginLeft: 1 }}
			>
				Cancel
			</Button>
		</ModalMainBox>)
	}

	useEffect(() => {
		fetchLeavesDropdown();
	}, []);

	useEffect(() => {
		if (selectedData === "") {
			const date = formatReverseDate(new Date());
			getleaveBalanaceData(date);
		}
		// eslint-disable-next-line
	}, []);

	return (
		<Box>
			<Box>
				<Box sx={{ marginBottom: "15px" }}>
					<Dropdown
						trigger={
							<Button variant="contained">
								{selectedData ? selectedData : "Select Year"}
							</Button>
						}
						menu={leavesDropdownArray.map((data, index) => (
							<DropdownNestedMenuItems
								// keepopen={false}
								label={data.key}
								rightIcon={
									data.value.length > 0 ? (
										<ArrowRight sx={{ marginLeft: "4px !important" }} />
									) : null
								}
								menu={
									data.value.length > 0
										? data.value.map((subData) => (
											<DropdownMenuItem
												keepopen={false}
												onClick={() => {
													handleChange(subData.EndDate, subData.month);
													setSelectedLeaveData(subData);
												}}
											>
												{subData.month}
											</DropdownMenuItem>
										))
										: null
								}
							/>
						))}
					/>
				</Box>
				{isLoading ? (
					<LoadingBox>
						<Loader />
					</LoadingBox>
				) : (
					<Accordion keepopen={false} sx={{ marginBottom: "10px" }} expanded>
						<EmployeesAccordionSummary
							expandIcon={<EmployeeExpandMoreIcon />}
							aria-controls={`panel-content`}
							id={`panel-header`}
						>
							Employees
						</EmployeesAccordionSummary>
						<EmployeesAccordionDetails>
							{employeeLeaveBalance?.map((option, index) =>
								option.empId === currentAdminId ? null : (
									<Accordion key={index} sx={{ marginBottom: "10px" }} keepopen={false}>
										<EmpNameAccordionSummary
											expandIcon={<EmpNameExpandMoreIcon />}
											aria-controls={`panel${index + 1}-content`}
											id={`panel${index + 1}-header`}
										>
											{option.name} ({option.empId})
											<Button onClick={() => openModal(option, selectedLeaveData)} variant="outlined" size="small" sx={{ marginLeft: 1 }}>
												Round Off
											</Button>
										</EmpNameAccordionSummary>
										<EmpNameAccordionDetails>
											<TableComponent array={option.data} />
										</EmpNameAccordionDetails>
									</Accordion>
								)
							)}
							{employeeLeaveBalance?.length === 0 ? (
								<NoRecordFound>No records found.</NoRecordFound>
							) : null}
						</EmployeesAccordionDetails>
					</Accordion>
				)}
			</Box>
			<DialogComponent open={modalOpen} onClose={() => onClose()} maxWidth="md" renderChildern={renderForm} />
		</Box>
	);
}

export default ViewAssignLeaveBalance;
